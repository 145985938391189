import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useGlobalStateContext } from '../../../context/GlobalContextProvider';
import { GlobalStyles } from './GlobalStyles';
import { lightTheme, darkTheme } from './Theme';
import PropTypes from 'prop-types';
import Header from '../header';

const Container = styled.div``;

const Main = styled.main`
  padding: 140px 0 0 0;
`;

const Layout = ({ children }) => {
  const [theme, setTheme] = useState('dark');
  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Container>
        <Header toggler={themeToggler} lightMode={theme === 'light'} />
        <Main>{children}</Main>
      </Container>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
