import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Open Sans, sans-serif
    transition: all .5s linear;
  }
  main {
    background: ${({ theme }) => theme.body};
    transition: all .5s linear;
  }
  nav {
    background: ${({ theme }) => theme.body};
    transition: all .5s linear;

    a, a:visited {
      color: ${({ theme }) => theme.link};
      font-size: 18px;
      letter-spacing: 2px;
    }
  }
  a {
    color: ${({ theme }) => theme.link};
    font-weight: 600;
    transition: all .5s linear;
  }
  a:visited {
    color: ${({ theme }) => theme.linkVisited};
    transition: all 1s linear;
  }
  `;
