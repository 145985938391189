export const lightTheme = {
  body: '#EFEFEF',
  text: '#301D41',
  toggleBorder: '#FFF',
  background: '#363537',
  link: '#6B3D91',
  linkVisited: '#AE278F',
};
export const darkTheme = {
  body: '#301D41',
  text: '#EFEFEF',
  toggleBorder: '#6B8096',
  background: '#999',
  link: '#FC632B',
  linkVisited: '#FF4700',
};
