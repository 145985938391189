import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Container = styled.div`
  max-width: 1100px;
  height 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media(min-width: 400px){
    flex-direction: row;
  }

  svg {
    width: 50%;
    height: 100%;
  }

`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 400px) {
    width: 50%;
    padding-right: 1rem;
  }

  h1 {
    margin: 16px 0;
  }

  h2 {
    margin: 0 0 40px 0;
  }
`;

const CTA = styled.a`
  text-align: center;
  padding: 0.75rem 1rem;
  max-width: 15rem;
  border: 0.125rem solid ${({ theme }) => theme.link};
  border-radius: 4rem;
  cursor: pointer;
  font-size: 18px;
`;

const Hero = ({ heading, description, ctaLink, children, ctaText }) => {
  return (
    <Container>
      <SideContainer>
        <h1>{heading}</h1>
        <h2>{description}</h2>
        <CTA href={ctaLink}>{ctaText}</CTA>
      </SideContainer>
      {children}
    </Container>
  );
};

Hero.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  children: PropTypes.node,
};

export default Hero;
