import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import SunSvg from '../../../assets/svgs/sun.svg';
import MoonSvg from '../../../assets/svgs/moon.svg';

const HeaderContainer = styled.nav`
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid rgb(236, 236, 236, 0.1);
`;

const LogoContainer = styled.div``;

const LinksContainer = styled.ul`
  width: 30%;
  display: flex;
  margin: 0;

  li {
    display: flex;
    justtify-content: center;
    align-items: center;
    list-style: none;
    margin: 0 12px;
    justify-content: center;

    a {
      color: ${props => props.theme.primaryTextColor};
    }
  }
`;

const ThemeButton = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 12px;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    stroke: ${props => (props.lightMode ? '#301D41' : '#fff')};
  }
`;

const Header = ({ toggler, lightMode }) => (
  <HeaderContainer>
    <Link to="/">
      <LogoContainer>
        <StaticImage
          src="../../../assets/images/original_logo.png"
          alt="Hyper Pixel logo"
          placeholder="blurred"
          layout="fixed"
          width={100}
          height={100}
        />
      </LogoContainer>
    </Link>
    <LinksContainer>
      <li>
        <Link to="/services/">Services</Link>
      </li>
      <li>
        <Link to="/projects/">Projects</Link>
      </li>
      <li>
        <Link to="/about-us/">About us</Link>
      </li>
      {/* <li>
        <Link to="/contact-us/">Contact us</Link>
      </li> */}
      <li>
        <ThemeButton onClick={toggler} lightMode={lightMode}>
          {!lightMode && <SunSvg />}
          {lightMode && <MoonSvg />}
        </ThemeButton>
      </li>
    </LinksContainer>
  </HeaderContainer>
);

Header.propTypes = {
  toggler: PropTypes.func.isRequired,
  lightMode: PropTypes.bool.isRequired,
};

export default Header;
